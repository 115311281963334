/* Typography */
:root,
body {
  font-size: 1rem;
  font-family: $type-1;
  font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $type-1;
  line-height: 1;
  color: theme-color(light);
}

p {
  font-size: $default-font-size;
  font-weight: 400;
}

.h1,
h1 {
  font-size: 2.5rem;
  font-weight: 600;
}

.h2,
h2 {
  font-size: 1.875rem;
  font-weight: 600;
}

.h3,
h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

.h4,
h4 {
  font-size: 1.25rem;
  font-weight: 600;
}

.h5,
h5 {
  font-size: 1.125rem;
  font-weight: 600;
}

.h6,
h6 {
  font-size: 1rem;
  font-weight: 600;
}

.display-1 {
  font-size: 3.75rem;
  @media (max-width: 991px) {
    font-size: 3rem;
  }
}

.display-2 {
  font-size: 3.125rem;
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
}

.display-3 {
  font-size: 2.5rem;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
}

.display-4 {
  font-size: 1.875rem;
  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}

.display-5 {
  font-size: 1.25rem;
  @media (max-width: 991px) {
    font-size: 1rem;
  }
}

.blockquote {
  padding: 1.25rem;
  border: 1px solid $border-color;
}

address {
  p {
    margin-bottom: 0;
  }
}
//blockqoute color variations
@each $color, $value in $theme-colors {
  .blockquote-#{$color} {
    @include blockquote($value);
  }
}

.card-title {
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  color: $title-color;
  font-weight: 600;
  font-size: 1.125rem;
  .rtl & {
    text-align: right;
  }
}

.card-subtitle {
  @extend .text-gray;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.card-description {
  margin-bottom: 0.9375rem;

  .rtl & {
    text-align: right;
  }
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.text-small {
  font-size: .75rem;
}

.icon-lg {
  font-size: 2.5rem;
}

.icon-md {
  font-size: 1.275rem;
}

.icon-sm {
  font-size: 1rem;
}